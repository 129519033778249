import { MinusIcon } from '@chakra-ui/icons'
import { Box, Button } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { useCallback } from 'react'
import {
  AssetTypeGroupChoices,
  AssetStatusChoices,
  AssetOwnerChoices
} from '@integrations/xplan-types/src/xplanEnums'

interface RemoveButtonProps {
  arrayHelpers: any
  index: number
  disableRemove?: boolean
  /** supplying fieldName assumes you have a list that stores items removed from the field array that's been passed to formik - see GoalsProgress.tsx for an example of use*/
  removedItemsFieldName?: string
  array?: any[] // pass array if you have removedItemsFieldName
}

const RemoveButton = ({
  arrayHelpers,
  index,
  disableRemove,
  removedItemsFieldName,
  array
}: RemoveButtonProps) => {
  const [, { value }, { setValue }] = useField(
    removedItemsFieldName ?? 'noFieldName'
  )

  const soldStatus = [
    AssetTypeGroupChoices.BusinessAssets,
    AssetTypeGroupChoices.LifeStyle,
    AssetTypeGroupChoices.RealEstate
  ]
  const closedStatus = [
    AssetTypeGroupChoices.Investments,
    AssetTypeGroupChoices.Retirement,
    AssetTypeGroupChoices.LiquidAssets,
    AssetTypeGroupChoices.ManagedInvestments
  ]

  //these are form labels not enums
  const lifestyleAssets = [
    'Antiques',
    'Artwork',
    'Household Contents',
    'Jewellery',
    'Vehicle / Boat',
    'Other Lifestyle'
  ]

  const removeItem = useCallback(() => {
    // // only execute special remove if item has an xplanItemId otherwise we may accidentally create a new undefined labelled item in xplan

    if (removedItemsFieldName && array && array[index]?.xplanItemId) {
      const removedItem = { ...array[index] }

      if (removedItem?.goal) {
        // we mark removed goals as cancelled in xplan
        removedItem.status = 'Cancelled'
      } else if (removedItem?.firstName && removedItem?.relationshipType) {
        // we mark removed dependants with a blank relationshipType
        removedItem.relationshipType = ''
      } else if (removedItem?.assetTypeGroup || removedItem?.assetType) {
        //stops form validation error if assetName is blank when deleted
        if (removedItem.assetName === '') {
          removedItem.assetName = ' '
        }

        if (removedItem?.assetTypeGroup) {
          if (soldStatus.includes(removedItem?.assetTypeGroup)) {
            removedItem.status = AssetStatusChoices.Sold
          }
          if (closedStatus.includes(removedItem?.assetTypeGroup)) {
            removedItem.status = AssetStatusChoices.Cancelled
          }
        }
        //these HCWHY assets are created in xplan when completed getting started module, indices stored client table,
        //but HCWHY gets its initial data from getting started table in database not xplan
        //so these assets have no assetTypeGroup available to filter so filtering by assetType
        if (!removedItem?.assetTypeGroup && removedItem?.assetType) {
          if (lifestyleAssets.includes(removedItem?.assetType)) {
            removedItem.status = AssetStatusChoices.Sold
          } else if (
            removedItem?.assetType === 'Savings' ||
            removedItem?.assetType === 'Investments'
          ) {
            //data coming from getting started has not ownership details, to avoid validation error we default to client
            if (!removedItem.assetOwner) {
              removedItem.assetOwner = AssetOwnerChoices.Client
            }
            removedItem.status = AssetStatusChoices.Cancelled
          }
        }
      }

      if (value) {
        setValue([...value, removedItem])
      } else {
        setValue([removedItem])
      }
    }

    arrayHelpers.remove(index)
  }, [removedItemsFieldName, arrayHelpers])

  return (
    <Button
      size='sm'
      variant='ghost'
      padding='0'
      textTransform='none'
      sx={{
        bg: 'none !important',
        boxShadow: 'none !important',
        alt: 'remove'
      }}
      onClick={removeItem}
      disabled={disableRemove}
    >
      <Box
        bg='blue.800'
        transition='background .3s ease'
        padding={1}
        borderRadius='50%'
        display='flex'
        h='24px'
        _hover={{ bg: 'blue.600' }}
        _focus={{ bg: 'blue.600' }}
      >
        <MinusIcon w={'16px'} h={'16px'} color='white' />
      </Box>
    </Button>
  )
}

export default RemoveButton
